import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Tooltip, tooltipClasses } from '@mui/material'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    fontSize: 16,
    color: '#000000',
    border: '1px solid black',
    padding: '12px 16px',
    fontFamily: 'Rethink Sans',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.2)'
  }
}))

StyledTooltip.propTypes = {
  title: PropTypes.string
}

export default StyledTooltip
