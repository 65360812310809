import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import PosterCard from 'components/card/poster'
import NewsCard from 'components/card/news'
import Icon from 'components/icon'
import Tooltip from 'components/tooltip'
import ZoomGallery from 'components/gallery/zoom'
import I18nImage from 'components/gallery/i18nImage'

import { streamFeatures } from '/static/data/instreamList.static'
import streamFunctions from '/static/data/feature.static'
import newsList from '/static/data/newsList.static'

const InstreamPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Deliver The Best QoE with Adaptive Streaming - Mlytics',
            metaDescription:
              "Ensure seamless video playback with Mlytics' adaptive streaming solutions. Optimize video delivery with our reliable and scalable platform.",
            metaUrl: 'https://www.mlytics.com/video-stream/instream-switching/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2022/06/logo_Mlytics_regular.png'
            }
          }}
        />
        <section className="container flex flex-col md:flex-row mb-20 mt-10">
          <div className="flex-1 mb-4">
            <div className="px-4">
              <h1 className="text-primary-600 my-6">{t('instreamSwitching.section1.title')}</h1>
              <h2 className="text-primary-600 text-2xl">{t('instreamSwitching.section1.subtitle')}</h2>
              <p className="py-5 text-gray-500">{t('instreamSwitching.section1.description')}</p>
              <div className="flex flex-col lg:flex-row gap-3 my-1">
                <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center flex-1 overflow-hidden md:px-6">
            <div className="player-wrapper aspect-ratio-16-9">
              <iframe src="/videos/10-segment-video-review.html" className="h-full w-full" />
            </div>
          </div>
        </section>
        <section className="bg-primary-500 px-4 md:px-10 py-20">
          <h2 className="text-white text-center">{t('instreamSwitching.section2.title')}</h2>
          <div className="container flex flex-col md:flex-row pt-20">
            <div className="flex-1 pt-2">
              <div>
                <h6 className="text-white text-2xl">{t('instreamSwitching.section2.list1.title')}</h6>
                <ul className="pl-5 list-disc mb-7 mt-1 text-white">
                  <li>{t('instreamSwitching.section2.list1.item1')}</li>
                  <li>{t('instreamSwitching.section2.list1.item2')}</li>
                  <li>{t('instreamSwitching.section2.list1.item3')}</li>
                </ul>
              </div>
              <div>
                <h6 className="text-white text-2xl">{t('instreamSwitching.section2.list2.title')}</h6>
                <ul className="pl-5 list-disc mb-7 mt-1 text-white">
                  <li>{t('instreamSwitching.section2.list2.item1')}</li>
                  <li>{t('instreamSwitching.section2.list2.item2')}</li>
                </ul>
              </div>
            </div>
            <div className="flex-1">
              <ZoomGallery>
                <I18nImage
                  src="https://static.mlytics.com/images/website/augment_video_smart_stream_switching.png"
                  alt={t('instreamSwitching.section2.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="bg-primary-400 flex flex-col md:flex-row justify-evenly">
          <div className="text-white py-16">
            <div className="text-center flex items-center">
              <Icon fontSize="120px" name="keyboard_arrow_up_outlined" />
              <span className="text-8xl md:text-9xl">30</span>
              <span className="text-6xl">%</span>
            </div>
            <div className="text-xl mt-6 flex items-center justify-center">
              <span>{t('instreamSwitching.section3.list1.item1.description')}</span>
              <Tooltip title={t('instreamSwitching.section3.list1.tooltip.hint1')}>
                <span className="flex items-center">
                  <Icon className="cursor-pointer ml-1" name="info" iconStyle="round" />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="text-white py-16">
            <div className="text-center flex items-center">
              <Icon fontSize="120px" name="keyboard_arrow_down_outlined" />
              <span className="text-8xl md:text-9xl">300</span>
              <span className="text-6xl">%</span>
            </div>
            <div className="text-xl mt-6 flex items-center justify-center">
              <span>{t('instreamSwitching.section3.list1.item2.description')}</span>
              <Tooltip title={t('instreamSwitching.section3.list1.tooltip.hint1')}>
                <span className="flex items-center">
                  <Icon className="cursor-pointer ml-1" name="info" iconStyle="round" />
                </span>
              </Tooltip>
            </div>
          </div>
        </section>
        <section className="px-4 md:px-10 py-20 container">
          <h2 className="text-center text-primary-600 text-5xl">{t('instreamSwitching.section4.title')}</h2>
          <p className="py-10 text-gray-700">{t('instreamSwitching.section4.description1')}</p>
          <ZoomGallery>
            <I18nImage
              src="https://static.mlytics.com/images/website/in_stream_switching_workflow.png"
              alt={t('instreamSwitching.section4.title')}
              style={{ height: 'auto', maxWidth: '100%' }}
            />
          </ZoomGallery>
          <p className="py-20 text-gray-700">{t('instreamSwitching.section4.description2')}</p>
          <div className="flex justify-center">
            <ZoomGallery>
              <I18nImage
                src="https://static.mlytics.com/images/website/in_streaming_switching_multi_cdn_controller.png"
                alt={t('instreamSwitching.section4.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </ZoomGallery>
          </div>
          <div className="leading-9 pl-3 border-l-4 border-primary-400 text-left mt-10">
            {streamFeatures.map((item, index) => (
              <div className="flex flex-col md:flex-row" key={index}>
                <div className="basis-1/3 font-black text-gray-900">{t(item.title)}</div>
                <div className="basis-2/3">{t(item.content)}</div>
              </div>
            ))}
          </div>
        </section>
        <section className="container pb-20 text-center">
          <h2 className="mb-9 text-primary-600 text-5xl">{t('instreamSwitching.section5.title')}</h2>
          <div className="flex justify-center">
            <div className="player-wrapper aspect-ratio-16-9">
              <iframe loading="lazy" src="/videos/in-stream-cdn-comparison.html" className="h-full w-full" />
            </div>
          </div>
          <p className="py-5 text-gray-700">{t('instreamSwitching.section5.videoFigure')}</p>
        </section>
        <section className="bg-gradient-blue px-4 md:px-10 py-16">
          <h2 className="text-center text-white text-5xl">{t('heading.learnMoreAboutStream')}</h2>
          <div className="flex flex-col md:flex-row justify-center pt-10">
            {streamFunctions.map((item, index) => {
              return index === 2 ? null : (
                <PosterCard
                  className="basis-0 mx-3 mb-3 md:mb-0"
                  key={index}
                  iconName={item.iconName}
                  title={t(item.title)}
                  paragraph={t(item.paragraph)}
                  anchorHref={item.anchorHref}
                  anchorText={t(item.anchorText)}
                />
              )
            })}
          </div>
          <div className="flex flex-col lg:flex-row gap-3 items-center justify-center mt-10">
            <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
              {t('button.freeTrial.0')}
            </Link>
            <Link
              to="/lp/get-a-demo/"
              className="btn btn-bold bg-white border-white text-secondary-600 inline-block"
              style={{ width: 208 }}
            >
              {t('button.getDemo.0')}
            </Link>
          </div>
        </section>
        <section className="hidden xl:block px-10 py-24">
          <h2 className="font-black text-center text-4.5xl text-primary-600">{t('heading.productPlatform')}</h2>
          <div className="flex justify-center mt-16">
            <img
              src="https://static.mlytics.com/images/website/mlytics_platform.svg"
              alt={t('imageAlt.platform')}
              style={{ height: 'auto', maxWidth: '1260px' }}
            />
          </div>
        </section>
        <section className="px-4 md:px-10">
          <h2 className="text-center text-primary-600 text-6xl mt-10">{t('heading.latestNews')}</h2>
          <div className="flex flex-col md:flex-row items-center justify-center mt-10 mb-20">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-1/4 mx-2 mb-3 md:mb-0"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default InstreamPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
